import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { SERVER_API_URL } from './../app-constants';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
export const DATE_FORMATE = 'yyyy-MM-dd';

@Injectable({
    providedIn: 'root',
})
export class PreBoardingService {
    finalCompletion: Subject<boolean> = new Subject<boolean>();
    basicInfoStatusChange: Subject<boolean> = new Subject<boolean>();
    empDataPersonalStatusChange: Subject<boolean> = new Subject<boolean>();
    epfDeclarationStatusChange: Subject<boolean> = new Subject<boolean>();
    nomineeDeclarationStatusChange: Subject<boolean> = new Subject<boolean>();
    policeVerificationStatusChange: Subject<boolean> = new Subject<boolean>();
    basicInformation: any;
    datePipe = new DatePipe('en-US');
    candidateID: any;
    serviceVariable = 'preboardingService';
    completeCurrentAddress: any;
    completePermanentAddress: string;
	counter = 0;
	count :BehaviorSubject<number>;
    docsName: any;
    docsRemarks: any;
    docsID: any;
    employeeType: any;
    personalInfoComplete: any;
    emergencyContactComplete: any;
    documentsComplete: boolean;
    qualificationComplete: boolean;
    policeComplete: boolean;
    personalComplete: boolean;
    nomineeComplete: boolean;
    familyComplete: boolean;
    experienceComplete: boolean;
    epfComplete: boolean;
    emergencyComplete: boolean;
    private emitEmpType;
    newEmpType: any;
    promises: any;
    constructor(private http: HttpClient, private https: HttpClient) {
		this.count = new BehaviorSubject(this.counter),
        this.emitEmpType = new BehaviorSubject('default message'),
        this.newEmpType = this.emitEmpType.asObservable();
	}
    getServiceName(name) {
        this.serviceVariable = name;
    }

    getProgress(res) {
        this.count.next(0);
        res.documents ? (this.documentsComplete = true , this.count.next(this.count.value+10)) : this.documentsComplete  = false ;
        res.emergency ? (this.emergencyComplete = true , this.count.next(this.count.value+10)) : this.emergencyComplete  = false ;
        res.epf ? (this.epfComplete = true , this.count.next(this.count.value+10)): this.epfComplete  = false ;
        res.experience ? (this.experienceComplete = true ,  this.count.next(this.count.value+10)) : this.experienceComplete  = false ;
        res.family ? (this.familyComplete = true , this.count.next(this.count.value+10)) : this.familyComplete  = false ;
        res.nominee ? (this.nomineeComplete = true , this.count.next(this.count.value+10)) : this.nomineeComplete  = false ;
        res.personal ? (this.personalComplete = true , this.count.next(this.count.value+20)) : this.personalComplete  = false ;
        res.police ? (this.policeComplete = true , this.count.next(this.count.value+10)) : this.policeComplete  = false ;
        res.qualification ? (this.qualificationComplete = true , this.count.next(this.count.value+10)) : this.qualificationComplete  = false ;
    }

    getDCProgress (res) {
        this.count.next(0);
        res.documents? (this.documentsComplete=true, this.count.next(this.count.value+20)):this.documentsComplete=false;
        res.emergency? (this.emergencyComplete=true, this.count.next(this.count.value+20)):this.emergencyComplete=false;
        res.experience ? (this.experienceComplete = true ,  this.count.next(this.count.value+20)) : this.experienceComplete  = false ;
        res.personal? (this.personalComplete=true, this.count.next(this.count.value+20)):this.personalComplete=false;
        res.qualification ? (this.qualificationComplete = true , this.count.next(this.count.value+20)) : this.qualificationComplete  = false ;
    }

    getVCProgress (res) {
        this.count.next(0);
        res.personal? (this.personalComplete=true, this.count.next(this.count.value+30)):this.personalComplete=false;
        res.emergency? (this.emergencyComplete=true, this.count.next(this.count.value+30)):this.emergencyComplete=false;
        res.documents? (this.documentsComplete=true, this.count.next(this.count.value+40)):this.documentsComplete=false;
    }

	nextCount(){
		this.count.next(this.count.value+10);
    }
    reduceCount () {
        this.count.next(this.count.value-10);
    }
    nextPersonalCount(){
        this.count.next(this.count.value+20);
    }
    changeEmpType(emp) {
        this.emitEmpType.next(emp);
    }
    vendorContractorCount () {
        this.count.next(this.count.value+30);
    }
    vendorContractorDocumentCount () {
        this.count.next(this.count.value+40);
    }
    dircetContractorCount () {
        this.count.next(this.count.value+20);
    }

    savepersonalInfo(candidateId, data, action): Observable<any> {
		candidateId = sessionStorage.getItem('candidateID');
        let payloadData: any = {};
        payloadData = {
            addressDetails: [
                {
                    addressType: 'CA',
                    city: data.currentAddress.cityName,
					contactNumber:data.currentAddress.contactNumber,
                    locality: data.currentAddress.streetNumber,
                    pinCode: data.currentAddress.pinCode,
					sameAddress:data.sameAsCurrent,
                    state: data.currentAddress.employeeState,
					id: data.currentAddress.addressId ? data.currentAddress.addressId :'',
                },
                {
                    addressType: 'PA',
                    city: data.permanentAddress.cityName,
                    locality: data.permanentAddress.streetNumber,
                    pinCode: data.permanentAddress.pinCode,
                    state: data.permanentAddress.employeeState,
					contactNumber:data.permanentAddress.contactNumber,
					id: data.permanentAddress.addressId ? data.permanentAddress.addressId : '',
                },
            ],
            candidateInformation: {
                id: data.candidateInfoId ? data.candidateInfoId : '',
                dateOfBirth:  this.datePipe.transform(data.personalInfoDob, DATE_FORMATE),
                emailId: data.emailAddress,
                name: data.employeeFullName,
                birthPlace:data.placeofBirth,
                citizenship: data.personCitizenship,
				maritalStatus: data.maritalStatus,
				gender: data.personGender,
				title: data.salutation,
                bloodGroup: data.bloodGroup,
                personalDescription: data.personalDescription,
				natureOfJob: data.prevJobType,
				height: data.height,
				tattoMarks: data.tattooMarks,
				deformity: data.deformity,
				bodyMark: data.identificationMarks,
				build: data.build,
				complexion: data.complexion,
				hairColor: data.hairColour,
                emailFlag: data.emailFlag,
                noticePeriod: data.noticePeriod,
                visaFlag: data.visaFlag,
                validUpto: data.validUpto,
                speciallyAbledFlag: data.speciallyAbledFlag,
                // otherCountryName: data.otherCountryName
            },
            phoneDetails: {
                number: data.mobileNumber,
				id: data.phoneNumberId ? data.phoneNumberId : '',
            },
            visaDetails: {
                visaFlag: data.visa,
                type: data.visaType,
                status: data.visaStatus,
				id: data.visaDetailsId ? data.visaDetailsId : '',
                validUpto: this.datePipe.transform(data.visaValidity, DATE_FORMATE),
            },
            passportDetails: {
                number: data.passportNumber,
                validTill: this.datePipe.transform(data.passportValidity,DATE_FORMATE),
				id: data.passportDetailsId ? data.passportDetailsId : '',
				document: 'PASSPORT',
            },
            govtDocumentDetails: {
                panNumber: data.panNumber,
                aadharNumber: data.aadharNumber,
				id: data.govtDocId ? data.govtDocId : '',
            },
            bankDetails:
				{
                accountNumber: data.bankAccountNo,
                ifscCode: data.ifscCode,
				branch: data.bankBranch,
				id: data.bankId ? data.bankId : '',
				name: data.accountName,
                candidateSelectedBankId:data.bankId ? data.bankId : '',
            },
            // bloodGroupDetails: {
            //     name: data.bloodGroup,
			// 	id: data.bloodGroupId ? data.bloodGroupId : '',
            // },
        };
        if (action === 'save') {
            return this.http
                .post(SERVER_API_URL + '/personalInformation/'+ candidateId, payloadData, {})
                .pipe(
                    map((response: HttpResponse<any>) => {
                        // this.empDataPersonalStatusChange.next(true);
                        return response;
                    })
                );
        } else if (action === 'update') {
            return this.http
                .put(SERVER_API_URL + '/personalInformation/'+ candidateId, payloadData, {})
                .pipe(
                    map((response: HttpResponse<any>) => {
                        // this.empDataPersonalStatusChange.next(true);
                        return response;
                    })
                );
        }
    }

	getAddressDetails(id): Observable<any>{
		return this.http.get(SERVER_API_URL + '/address/' + id, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
	}

    getpersonalInfo(id): Observable<any> {
        return this.http.get(SERVER_API_URL + '/personalInformation/' + id, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }

    getemployeRef(id): Observable<any> {
        return this.http.get(SERVER_API_URL + '/references/allReferences/' + id, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
        this.basicInfoStatusChange.next(true);
    }

    getEpfDetails(id): Observable<any> {
        return this.http.get(SERVER_API_URL + '/epf/' + id, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }

    getNomineeDec(id): Observable<any> {
        return this.http
            .get(SERVER_API_URL + '/nominees/' + id, {})
            .pipe(
                map((response: HttpResponse<any>) => {
                    return response;
                })
            );
    }
    extractPhoneNumber(data) {
        let countryCode = data.substring(0, data.indexOf('+'));
        let phoneNumber = data.substring(data.indexOf(' ') + 1);
        return {
            countryCode: countryCode,
            phoneNumber: phoneNumber,
        };
    }
	getEmergencyInfo(id): Observable<any> {
        return this.http
            .get(SERVER_API_URL + '/emergencyContacts/' + id, {})
            .pipe(
                map((response: HttpResponse<any>) => {
                    return response;
                })
            );
    }
    emergencyContactSave(candidateId, data, action): Observable<any> {
        const payloadData = {
            candidateId: candidateId,
            city: data.cityName,
            name: data.contactName,
            mobileNumber: data.contactNumber,
            locality: data.houseNo,
            pinCode: data.areaPincode,
            Percentage: data.Percentage,
            relationship: data.relationship,
            state: data.stateName,
			primary: data.primaryContactFlag,
			id: data.id ? data.id : '',
        };

        if (action === 'save') {
            return this.http
                .post(SERVER_API_URL + '/emergencyContacts/'+ candidateId, payloadData, {})
                .pipe(
                    map((response: HttpResponse<any>) => {
                        return response;
                    })
                );
        } else if (action === 'update') {
            return this.http
                .put(SERVER_API_URL + '/emergencyContacts', payloadData, {})
                .pipe(
                    map((response: HttpResponse<any>) => {
                        return response;
                    })
                );
        }
    }
	getEntireFamilyInformation(id): Observable<any> {
        return this.http.get(SERVER_API_URL + '/families/allFamilies/' + id, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }
  

    saveFamilyInfo(candidateId, data, action): Observable<any> {
        const payloadData = {
			candidateId: candidateId,
            age: data.age,
			dateOfBirth: this.datePipe.transform(data.dateOfBirth,DATE_FORMATE),
			name:data.fullName,
			relationship: data.relationship,
			id: data.id ? data.id : '',
        };
        if (action === 'save') {
            return this.http
                .post(SERVER_API_URL + '/families/' + candidateId, payloadData, {})
                .pipe(
                    map((response: HttpResponse<any>) => {
                        return response;
                    })
                );
        } else if (action === 'update') {
            return this.http
                .put(SERVER_API_URL + '/families', payloadData, {})
                .pipe(
                    map((response: HttpResponse<any>) => {
                        return response;
                    })
                );
        }
    }

	getQualificationInfo(id): Observable<any> {
        return this.http.get(SERVER_API_URL + '/qualifications/allQualifications/' + id, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }

    saveQualificationDetails(candidateId, data,action): Observable<any> {
		const payLoaddata = {
			candidateId: candidateId,
			qualification: data.qualification,
			yearOfPassing:data.yearOfPassing,
            // yearOfPassing: this.datePipe.transform(data.yearOfPassing, DATE_FORMATE),
			university: data.universityName,
			degree: data.degree,
			id: data.id ? data.id : '',
		};
		if (action === 'save') {
			return this.http.post(SERVER_API_URL + '/qualifications/' + candidateId, payLoaddata, {}).pipe(
				map((response: HttpResponse<any>) => {
					return response;
				})
			);
		} else if (action === 'update') {
			return this.http.put(SERVER_API_URL + '/qualifications', payLoaddata,{}).pipe(map((response: HttpResponse<any>)=>{
				return response;
			}));
		}
    }

	getWorkEx(id): Observable<any> {
        return this.http.get(SERVER_API_URL + '/workExperiences/allWorkExperiences/' + id, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }

    saveworkExperience(candidateId, data, action): Observable<any> {
		const payloadData = {
			candidateId: candidateId,
			ctcPerAnnum: data.ctcPerAnnum,
			dateOfJoining: this.datePipe.transform(data.dateOfJoining,DATE_FORMATE),
			dateOfLeaving: this.datePipe.transform(data.dateOfLeaving,DATE_FORMATE),
			designationHeld: data.designationHeld,
			reportingManager: data.reportingManager,
			reportingManagerMobileNumber: data.rmMobileNumber,
			employerNameAndLocation: data.employerNameLocation,
			id: data.id ? data.id : ''
		};
        if (action === 'save') {
            return this.http.post(SERVER_API_URL + '/workExperiences/' + candidateId, payloadData, {}).pipe(
                map((response: HttpResponse<any>) => {
                    return response;
                })
            );
        } else if (action === 'update') {
            return this.http.put(SERVER_API_URL + '/workExperiences', payloadData, {}).pipe(
                map((response: HttpResponse<any>) => {
                    return response;
                })
            );
        }
    }

    updateTotalExp(candidateID, totalMonths): Observable<any> {
        let payload = {
            candidateId : Number(candidateID),
            totalMonthsOfExperience: totalMonths
        }
        return this.http.put(SERVER_API_URL + `/experience/candidateInformation`,payload,{});
    }

    saveReference(candidateId, data,action): Observable<any> {
		const payLoadData = {
			candidateId: candidateId,
			address: data.refemail,
			name: data.refName,
            referenceType: 'PROFESSIONAL',
			contact: data.refMobileNumber,
			occupation: data.refOccupation,
			id: data.id? data.id : ''
		};
		if (action === 'save'){
        return this.http.post(SERVER_API_URL + '/references/' + candidateId, payLoadData, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
		} else if (action==='update'){
			return this.http.put(SERVER_API_URL + '/references', payLoadData, {}).pipe(
                map((response: HttpResponse<any>) => {
                    return response;
                })
            );
		}
    }

   savePoliceVerification(candidateId, data, action): Observable<any> {
        let payloadData: any = {};
        payloadData = {
            candidateId: candidateId,
			id: data.policeVerificationId ? data.policeVerificationId : '',
			postOffice: data.empPostOffice,
			policeStation: data.empPoliceStation,
			nameAddressOfSurety: data.suretyDetails,
			proofOfIdentity: data.empIdentityProof,
			documentId: data.empIDNumber,
			addressDetails: {
				addressType: 'PA',
				city: data.empDistrict,
				id: data.addressId ? data.addressId : '',
				locality: data.empAddress,
				pinCode: data.empPincode,
				state: data.empState,
			},
			candidateInfoDetails: {
				name: data.empFullName,
				dateOfBirth: this.datePipe.transform(data.empDOB, DATE_FORMATE),
                personalDescription: data.personalDescription,
				natureOfJob: data.prevJobType,
				height: data.height,
				tattoMarks: data.tattooMarks,
				deformity: data.deformity,
				bodyMark: data.identificationMarks,
				build: data.build,
				complexion: data.complexion,
				hairColor: data.hairColour,
				id: data.candidateDetailsId ? data.candidateDetailsId : '',
                citizenship: data.citizenship,
                emailFlag: data.emailFlag,
                emailId: data.emailAddress,
                gender: data.genderId,
                maritalStatus: data.maritalStatus,
                noticePeriod: data.noticePeriod,
                visaFlag: data.visaFlag,
                validUpto: data.validUpto,
                speciallyAbledFlag: data.speciallyAbledFlag,
                title: data.title,
                bloodGroup:data.bloodGroup,
                approvedZipFileUrl: data.approvedZipFileUrl,
                birthPlace: data.birthPlace,
                // joiningStatus: data.joiningStatus,
                // languageSpoken: data.languageSpoken,
                // documentUploadStatus: data.documentUploadStatus,
			},
			workExperienceDetails: {
				employerNameandLocation: data.previousEmployer,
				dateOfLeaving:this.datePipe.transform(data.dateOfLeaving, DATE_FORMATE),
				id: data.workExId ? data.workExId : '',
			},
			referencesDetailsList: [{
				referenceType: 'PERSONAL',
				name: data.primaryReferenceName,
				address: data.primaryReferenceAddress,
				relation: data.primaryRefrenceRelation,
				id: data.primaryRefId ? data.primaryRefId : '',
			},
			{
				referenceType: 'PERSONAL',
				name: data.secondaryReferenceName,
				address: data.secondaryReferenceAddress,
				relation: data.secondaryReferenceRelation,
				id: data.secondaryRefId ? data.secondaryRefId : '',
			}],
			familyDetails:[{
				name: data.empFatherName,
				id: data.fatherId ? data.fatherId : '',
			},
			{
				name: data.empMotherName,
				id: data.motherId ? data.motherId : '',
			},
        ],
        };
        if (action === 'save') {
            return this.http
                .post(SERVER_API_URL + '/policeverification', payloadData, {})
                .pipe(
                    map((response: HttpResponse<any>) => {
                        return response;
                    })
                );
        } else if (action === 'update') {
            return this.http
                .put(
                    SERVER_API_URL + '/policeverification',
                    payloadData,
                    {}
                )
                .pipe(
                    map((response: HttpResponse<any>) => {
                        return response;
                    })
                );
        }
    }

    getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    epfDetailsSave(candidateId, data, action): Observable<any> {
        let payloadData: any = {};
        data.kycDoc.forEach((element) => {
            element.candidateId = candidateId;
        });
		payloadData = {
			candidateId:candidateId,
            pfMemberId: data.allotedPF ? data.allotedPF : '',
			candidateInfoDetails:{
				title: data.salutation,
				name: data.empName,
				dateOfBirth:this.datePipe.transform(data.epfDob,DATE_FORMATE),
				id: data.candidateInfoID ? data.candidateInfoID : '',
				gender: data.gender,
				emailId: data.empEmail,
                citizenship: data.citizenship,
                visaFlag: data.visaFlag,
                emailFlag: data.emailFlag,
				maritalStatus: data.maritalStatus,
			},
			epfMemberScheme: data.epfMember,
            speciallyAbled:data.speciallyAbled,
            epsMemberScheme: data.epsMember,
            relationship: data.relationInLastField,
			// familyDetails: [{
			// 	id: data.familyDetailsID ? data.familyDetailsID : '',
			// 	name: data.fatherName,
            //     relationship: data.relationInLastField,
            // }],
            nameOfRelation: data.fatherName,
			id:data.epfId ? data.epfId : '',
			internationalWorker: data.internationalWorker,
			kycDetails: data.kycDoc,
			phoneDetails:{
				number: data.empMobile,
				id: data.phoneDetailsID ? data.phoneDetailsID : '',
			},
		}
			payloadData.qualification = data.educationQualification;
			if (data.epsMember) {
				payloadData.uanNumber = data.eps.uanNumber;
				payloadData.previousPFMemberId = data.eps.perviousPfID;
				payloadData.schemeCertificateNumber = data.eps.schemeCertificateNumber;
				payloadData.ppoNumber = data.eps.ppoNumber;
				payloadData.previousPFMemberIdExitDate = this.datePipe.transform(data.eps.dateOfExit,DATE_FORMATE);
			}
			if (data.internationalWorker) {
				payloadData.passportDetails = {
					number: data.internationalWorkerDetails.passportNumber,
					validFrom: this.datePipe.transform(data.internationalWorkerDetails.passpotValidFrom,DATE_FORMATE),
					validTill: this.datePipe.transform(data.internationalWorkerDetails.passpotValidTill,DATE_FORMATE),
					country: data.internationalWorkerDetails.countryOfOrigin,
					candidateId: candidateId,
					id: data.internationalWorkerDetails.internationalWorkerId ? data.internationalWorkerDetails.internationalWorkerId : ''
				};
		}
        if (action === 'save') {
            return this.http.post(SERVER_API_URL + '/epf', payloadData, {}).pipe(
                map((response: HttpResponse<any>) => {
                    return response;
                })
            );
        } else if (action === 'update') {
            return this.http
                .put(SERVER_API_URL + '/epf', payloadData, {})
                .pipe(
                    map((response: HttpResponse<any>) => {
                        return response;
                    })
                );
        }
    }

	saveNomineeDecalaration(candidateId, data, action,nomineeType):Observable<any>{
		candidateId = sessionStorage.getItem('candidateID');
		let payloadData: any = {};
		if (nomineeType === 'epfList'){
			payloadData = {
				nomineeType: 'EPF',
				id: data.EPFid ? data.EPFid : '',
				name: data.selectNominee,
				relationship: data.relationWithNominee,
				dateOfBirth: this.datePipe.transform(data.dob,DATE_FORMATE),
				locality: data.houseNo,
				city: data.cityName,
				state: data.stateName,
				pincode: data.areaPincode,
               percentageShareToNominee: data.percentage,
                // minor: false,
				nameAndAddress: data.guardianDetails,
			}
		} else if (nomineeType === 'epsList'){
			payloadData = {
				nomineeType: 'EPS',
				id: data.EPSid ? data.EPSid : '',
				name: data.nameOfNominee,
				relationship: data.relationWithNominee,
				age: data.age,
				locality: data.houseNo,
				city: data.cityName,
				state: data.stateName,
				pincode: data.areaPincode,
                percentageShareToNominee: data.percentage,
			}
		}else if (nomineeType === 'GRATUITY'){
			payloadData = {
				nomineeType: 'GRATUITY',
				id: data.Gratuityid ? data.Gratuityid : '',
				name: data.selectNomineeGratuity,
				relationship: data.relationWithNomineeGratuity,
				age: data.age,
                dateOfBirth: this.datePipe.transform(data.Gratuitydob,DATE_FORMATE),
				locality: data.houseNo,
				city: data.cityName,
				state: data.stateName,
				pincode: data.areaPincode,
                percentageShareToNominee: data.percentage,
                nameAndAddress: data.guardianDetailsGratuity,
			}
		} else if (nomineeType === 'widowPensionList'){
			payloadData = {
				nomineeType: 'WIDOW_PENSION',
				id: data.pensionId ? data.pensionId : '',
				name: data.nameOfNominee,
				relationship: data.relationWithNominee,
				dateOfBirth: this.datePipe.transform(data.dob,DATE_FORMATE),
				locality: data.houseNo,
				city: data.cityName,
				state: data.stateName,
				pincode: data.areaPincode,
                percentageShareToNominee: data.percentage,
			}
		}
		if (action === 'save'){
			return this.http.post(SERVER_API_URL + '/nominees/'+ candidateId, payloadData, {})
			.pipe(map((response: HttpResponse<any>) => {
				return response;
				})
			);
		} else if (action === 'update'){
			return this.http.put(SERVER_API_URL + '/nominees', payloadData, {})
			.pipe(map((response: HttpResponse<any>) => {
				return response;
				})
			);
		}
	}
	getNomineeData(id): Observable<any>{
		return this.http.get(SERVER_API_URL + '/nominees/' + id, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
	}
	getAllNomineesData(candidateId): Observable<any>{
		return this.http.get(SERVER_API_URL + '/nominees/allNominees/' + candidateId, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
	}

	getAllPersonalInformation(): Observable<any>{
		return this.http.get(SERVER_API_URL + '/getAllPersonalInformation').pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
	}
    getInitPolice(id): Observable<any> {
        return this.http
            .get(SERVER_API_URL + '/policeverification/' + id, {})
            .pipe(
                map((response: HttpResponse<any>) => {
                    return response;
                })
            );
    }

    getCandidateList(): Observable<any> {
        return this.http.get(SERVER_API_URL + '/candidates/allCandidates').pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }

    createCandidate(data): Observable<any> {
        let payloadData = {
            accountCreatedDate: null,
            emailId: data.emailAddress,
            name: data.fullName,
            contactNumber:data.contactNumber,
            employeeType:data.employeeType,
            validUpto: this.datePipe.transform(data.expectedDOJ, 'yyyy-MM-dd'),
            emailFlag: data.emailCheckbox,
        };
        return this.http.post(SERVER_API_URL + '/candidates', payloadData, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }
    updateCandidateDashboard(data): Observable<any> {
        let payloadData = {
            emailId: data.emailAddress,
            name: data.fullName,
            validUpto: this.datePipe.transform(data.expectedDOJ, 'yyyy-MM-dd'),
            emailFlag: data.emailFlag,
            candidateId: data.canID,
            joiningStatus: data.joiningStatus,
            userId: data.userId,
            password: data.password,
            contactNumber:data. contactNumber,
            employeeType:data.employeeType,
            accountCreatedDate: this.datePipe.transform(data.accountCreatedDate,'yyyy-MM-dd')
        };
        return this.http
            .put(SERVER_API_URL + '/candidates', payloadData, {})
            .pipe(
                map((response: HttpResponse<any>) => {
                    return response;
                })
            );
    }

    deleteCandidate(id): Observable<any> {
        return this.http.delete(SERVER_API_URL + '/candidates/' + id);
    }
    exportEpfForm(id, action): Observable<any> {
        if (action === 'epf') {
            return this.http.get(SERVER_API_URL + '/api/epfReport/' + id, {
                responseType: 'blob',
            });
        } else if (action === 'nominee') {
            return this.http.get(SERVER_API_URL + '/api/nomineeForm/' + id, {
                responseType: 'blob',
            });
        } else if (action === 'nomineDec') {
            return this.http.get(SERVER_API_URL + '/api/nomineeDeclaration/' + id, {
                responseType: 'blob',
            });
        } else if (action === 'policeVer') {
            return this.http.get(
                SERVER_API_URL + '/api/policeVerificatioReport/' + id,
                { responseType: 'blob' }
            );
        }
    }

    retrieveDocumentStatus (id): Observable<any> {
        return this.http.get(SERVER_API_URL + '/documents/status/' + id).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }

    documentStatusChange (id, approveStatus, data): Observable<any>{
		return this.http.put(SERVER_API_URL + '/documents/updateStatus/' + id + '?conditionallyApproved=' + approveStatus, data, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
	}

    getexport(url): Observable<any>{
		return this.http.get(url,{
            responseType:'blob'
        });

    }
    
    getPBFilter (url): Observable<any> {
        return this.http.get(url);
    }

	uploadFileToServer(file: File, id, isHR): Observable<HttpEvent<any>>{
		const formData: FormData = new FormData();
		formData.append('file',file);
		const req = new HttpRequest('POST', SERVER_API_URL + '/documents/uploadZip/' + id + '?fromHr=' +isHR,formData, {
			reportProgress: true,
			responseType: 'json'
		});
		return this.http.request(req);
    }

    pushFileToStorage(file: File,id,isHR,isOnbehalfOfCandidate): Observable<any> {
        const data: FormData = new FormData();
        data.append('file', file);
        return this.http.post(`${SERVER_API_URL}/documents/uploadZip/${id}?fromHr=${isHR}&isOnbehalfOfCandidate=${isOnbehalfOfCandidate}`,data,{
            reportProgress: true,
            responseType: 'text'
        })
    }
    
    public postFile(fileToUpload: File, id, isHR): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();
        formData.append('file', fileToUpload);
        let params = new HttpParams();
        const req=new HttpRequest('POST', SERVER_API_URL+'/documents/uploadZip/'+id+'?fromHr='+isHR, formData, {
            // params: params,
            reportProgress: true,
          responseType: 'json'
        });
        return this.http.request(req);
      }
	downloadZip(id,isHr,isOnbehalfOfCandidate){
		return this.http.get(`${SERVER_API_URL}/documents/downloadZip/${id}?fromHr=${isHr}&isOnbehalfOfCandidate=${isOnbehalfOfCandidate}`,{
			responseType: 'blob',
		});
	}
	retrieveDocuments(id){
		return this.http.get(SERVER_API_URL + '/documents/' + id, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }
    retrieveDocStatus (id) {
        return this.http.get(SERVER_API_URL+'/documents/status/'+id, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }
    getGeneralDocuments (fileName) {
        return this.http.get(SERVER_API_URL+'/generaldocuments/'+fileName, { responseType: 'blob',}
        );
    }

    retrieveProfilePicture (id): Observable<any> {
        return this.http.get(SERVER_API_URL+'/profilepicture/'+id).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }


    saveImage (resourceCode, imageData): Observable<HttpResponse<any>> {
        return this.http
            .put(SERVER_API_URL+'/upload/profilepicture/'+resourceCode, imageData, {}).pipe(
                map((response: HttpResponse<any>) => {
                    return response;
                })
            );
    }

    candidateProgressStatus (id): Observable<any> {
        return this.http.get(SERVER_API_URL+'/candidateDataSubmissionStatus/'+id).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }

    retrieveDocumentCheckList (employeeType): Observable<any> {
        return this.http.get(SERVER_API_URL+'/documentchecklist/'+employeeType).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }
    documentSubmissionMail(candidateID,fromHr,isOnbehalfOfCandidate): Observable<any> {
        return this.http.put(SERVER_API_URL + '/documents/update/documentsubmissionInfo/' + candidateID +'?fromHr=' + fromHr+ '&isOnbehalfOfCandidate=' + isOnbehalfOfCandidate, {}).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }

    /* On-grid background verification  Registration*/
    getCandidateDetailsForRegistration(candidateID): Observable<any>{
        return this.http.get(SERVER_API_URL + '/bgv/candidatedetails/'+ candidateID).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }

    registerCandidateToOngrid(payload, candidateID) : Observable<any> {
        return this.http.put(SERVER_API_URL + '/bgv/candidatedetails/register/'+candidateID,payload,{});
    }

    /* On-grid educational documents verification */
    uploadEduDoctoOngrid(individualId, formData,candidateID) : Observable<any>{
        const formData1 = new FormData();
        for (let key in formData.file) {
            formData1.append(`file`,formData.file[key])
        }
        formData1.append('state',formData.state);
        formData1.append('id',formData.id);
        formData1.append('fileName',formData.fileName);
        formData1.append('issueDate',formData.issueDate);
        formData1.append('level',formData.level);
        formData1.append('nameAsPerDocument',formData.nameAsPerDocument);
        formData1.append('nameOfInstitute',formData.nameOfInstitute);
        formData1.append('registrationNumber',formData.registrationNumber); 
        return this.http.post(SERVER_API_URL + '/bgv/upload/education/doc/' + individualId + '/'+candidateID, formData1,{});
    }

    requestEducationVerification(individualId, payload,candidateID) {
        return this.http.post(SERVER_API_URL + '/bgv/verify/education/doc/' + individualId + '/' + candidateID,payload,{});
    }

    getEducationVerificationStatus(individualID,candidateID): Observable<any> {
        return this.http.get(SERVER_API_URL + `/bgv/education/${individualID}/${candidateID}`);
    }

    updateEducationalDetails(candidateID,individualID,formData): Observable<any> {
        const formData1 = new FormData();
        for (let key in formData.file) {
            formData1.append(`file`,formData.file[key])
        }
        formData1.append('state',formData.state);
        formData1.append('id',formData.id);
        formData1.append('fileName',formData.fileName);
        formData1.append('issueDate',formData.issueDate);
        formData1.append('level',formData.level);
        formData1.append('nameAsPerDocument',formData.nameAsPerDocument);
        formData1.append('nameOfInstitute',formData.nameOfInstitute);
        formData1.append('registrationNumber',formData.registrationNumber); 
        return this.http.post(SERVER_API_URL + '/bgv/update/education/doc/'+individualID+'/'+candidateID,formData1,{});
    }

    getCandidateEduDetails(candidateID) : Observable<any>{
        return this.http.get(SERVER_API_URL + '/bgv/candidate/education/details/'+candidateID).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }
    /* On-grid CCRV */
    requestCCRV(candidateID,individualID,payload): Observable<any> {
        return this.http.post(SERVER_API_URL + '/bgv/ccrv/'+individualID+'/'+candidateID,payload,{});
    }

    getCCRVDetails(individualID, candidateID): Observable<any> {
        return this.http.get(SERVER_API_URL + `/bgv/ccrv/status/${individualID}/${candidateID}`).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }

    /* On-grid Credit Check */
    postCreditCheck(candidateID, individualID,payload): Observable<any> {
        return this.http.post(SERVER_API_URL + `/bgv/cc/${candidateID}/${individualID}`,payload,{});
    }

    getCandidateDetailsCC(candidateID): Observable<any> {
        return this.http.get(SERVER_API_URL+`/bgv/cc/${candidateID}`).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }

    getCCVerificationStatus(candidateID,individualID): Observable<any> {
        return this.http.get(SERVER_API_URL + `/bgv/cc/${candidateID}/${individualID}`).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }
    /* On-grid Employment Documents verification */
    uploadEmploymentDocs(individualID, candidateID,payload): Observable<any> {
        const formData1 = new FormData();
        if (payload.salaryslip !== '') {
        for (let key in payload.salaryslip) {
            formData1.append(`salaryslip`,payload.salaryslip[key])
        }
    }
        formData1.append('employeeId',payload.employeeId);
        formData1.append('employerName',payload.employerName);
        formData1.append('nameAsPerEmployerRecords',payload.nameAsPerEmployerRecords);
        formData1.append('employmentRecordId',payload.employmentRecordId);
        formData1.append('individualId',payload.individualId);
        formData1.append('joiningDate',payload.joiningDate);
        formData1.append('lastWorkingDate',payload.lastWorkingDate);
        formData1.append('lastDesignation',payload.lastDesignation);
        if (payload.experienceletter !== '') {
            formData1.append('experienceletter',payload.experienceletter);
        }
        if (payload.appointmentletter !== '') {
            formData1.append('appointmentletter',payload.appointmentletter);
        }
        return this.http.post(SERVER_API_URL + `/bgv/upload/employement/doc/${individualID}/${candidateID}`,formData1);
    }

    requestEmploymentVerification(individualID,candidateID,payload): Observable<any> {
        return this.http.post(SERVER_API_URL + `/bgv/verify/employement/doc/${individualID}/${candidateID}`,payload,{});
    }

    updateEmploymentDocument(individualID, candidateID,payload): Observable<any> {
        const formData1 = new FormData();
        if (payload.salaryslip !== '') {
        for (let key in payload.salaryslip) {
            formData1.append(`salaryslip`,payload.salaryslip[key])
        }
    }
        formData1.append('employeeId',payload.employeeId);
        formData1.append('employerName',payload.employerName);
        formData1.append('nameAsPerEmployerRecords',payload.nameAsPerEmployerRecords);
        formData1.append('employmentRecordId',payload.employmentRecordId);
        formData1.append('individualId',payload.individualId);
        formData1.append('joiningDate',payload.joiningDate);
        formData1.append('lastWorkingDate',payload.lastWorkingDate);
        formData1.append('lastDesignation',payload.lastDesignation);
        if (payload.experienceletter !== '') {
            formData1.append('experienceletter',payload.experienceletter);
        }
        if (payload.appointmentletter !== '') {
            formData1.append('appointmentletter',payload.appointmentletter);
        }
        return this.http.post(SERVER_API_URL + `/bgv/update/employement/doc/${individualID}/${candidateID}`,formData1,{});
    }

    getEmployment(candidateID): Observable<any> {
        return this.http.get(SERVER_API_URL + `/bgv/employement/docs/${candidateID}`);
    }

    getEmploymentVerificationStatus(individualID,candidateID): Observable<any> {
        return this.http.get(SERVER_API_URL + `/bgv/employement/${individualID}/${candidateID}`);
    }

    /* Address Verification */

    initiateAddressverification(individualID,candidateID,payload): Observable<any> {
        return this.http.post(SERVER_API_URL + `/bgv/addressverificationreq/${individualID}/${candidateID}`,payload,{});
    }

    getAddressDetailsforVerification(candidateID): Observable<any> {
        return this.http.get(SERVER_API_URL+ `/bgv/addressDetails/${candidateID}`);
    }

    getAddressVerificationStatus(individualID,verificationType): Observable<any> {
        return this.http.get(SERVER_API_URL + `/bgv/addrverificationstatusreq/${individualID}/${verificationType}`);
    }

    /* Overall Status */

    getOverallBGVStatus(candidateID,individualID): Observable<any> {
        return this.http.get(SERVER_API_URL + `/bgv/candidate/overallstatus/${candidateID}/${individualID}`);
    }

    /* Insufficiency Implementation  */
    getInsufficiencies(candidateID, individualID, requestID): Observable<any> {
        return this.http.get(SERVER_API_URL + `/bgv/insufficiencies?candidateId=${candidateID}&individualId=${individualID}&requestId=${requestID}`);
    }

    updateInsufficiency (individualID, payload): Observable<any> {
        return this.http.post(SERVER_API_URL + `/bgv/update/insuffiency/${individualID}`,payload,{});
    }

    /* BGV Reports */

    exportBGVStatusReport(): Observable<any> {
        return this.http.get(SERVER_API_URL + `/api/candidateBGVStatusReport/export`,{
            responseType: 'blob',
        });
    }

    exportInsufficiencyReport(): Observable<any> {
        return this.http.get(SERVER_API_URL + `/api/candidateBGVInsufficiencyStatusReport/export`,{
            responseType: 'blob'
        });
    }

    getBGVStatusLink(candidateID): Observable<any> {
        return this.http.get(SERVER_API_URL + `/bgv/${candidateID}`);
    }

    downloadCandidateIndividualReport(individualID): Observable<any> {
        return this.http.get(SERVER_API_URL + `/api/candidateBGVStatusReport/pdf/export/${individualID}`);
    }

    bgvInitiationConsent(candidateID,consentFlag): Observable<any> {
        return this.http.post(SERVER_API_URL + `/bgv/consent/${candidateID}/${consentFlag}`,null);
    }

    getConsent(candidateID): Observable<any> {
        return this.http.get(SERVER_API_URL + `/bgv/consent/${candidateID}`);
    }
}
